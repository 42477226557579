<template>
  <input
    ref="checkbox"
    v-model="computedModel"
    :value="value"
    type="checkbox"
    :disabled="disabled"
    :true-value="trueValue"
    :false-value="falseValue"
    v-on="inputListeners"
  />
</template>

<script>
// TODO: удалить в пользу form-fields/checkbox, т.к. чекбокс без лейбла вряд ли возможен
export default {
  name: 'VCheckbox',
  model: {
    prop: 'checked',
    event: 'change-value'
  },
  props: {
    checked: {
      type: [Array, Number, String, Boolean],
      default: null
    },
    value: {
      type: [Number, String, Boolean],
      default: ''
    },
    trueValue: {
      type: [Number, String, Boolean],
      default: true
    },
    falseValue: {
      type: [Number, String, Boolean],
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change', 'change-value'],
  computed: {
    inputListeners() {
      return {
        ...this.$listeners,
        change: (event) => {
          // TODO: найти использование и удалить второй аргумент
          this.$emit('change', event.target.checked, event);
        }
      };
    },
    computedModel: {
      get() {
        return this.checked;
      },
      set(value) {
        this.$refs.checkbox.checked = this.checked === this.trueValue;
        this.$emit('change-value', value);
      }
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
