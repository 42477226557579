<template>
  <div :class="$style.wrapper">
    <span v-if="subTitle" :class="$style.subTitle">{{ subTitle }}</span>
    <div :class="$style.flex">
      <div v-if="isOrg" :class="$style.button" @click="$emit('back')">
        <base-icon type="settings-arrow" :class="$style.icon" />
      </div>
      <router-link v-if="showBack && !isOrg" :class="$style.button" :to="{ path: '/' }">
        <base-icon type="settings-arrow" :class="$style.icon" />
      </router-link>
      <h1 :class="$style.title" v-html="title" />
    </div>
    <div :class="$style.delimiter" />
  </div>
</template>

<script>
import BaseIcon from '@/components/icon.vue';

export default {
  name: 'SettingTitle',
  components: {
    BaseIcon
  },
  props: {
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      default: null
    },
    showBack: {
      type: Boolean,
      default: false
    },
    isOrg: {
      type: Boolean,
      default: false
    }
  },
  emits: ['back']
};
</script>

<style lang="less" module>
@import '~@less/common/variables.less';

.wrapper {
  margin: 40px 0 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}
.button {
  position: relative;
  cursor: pointer;
  padding: 15px;
  margin: -15px 0 -15px -15px;
  &:before {
    content: '';
    border-left: 1px solid #dcdcdc;
    position: absolute;
    left: 49.52px;
    height: 25px;
    top: 15px;
    z-index: 1;
  }
  &:hover {
    .icon {
      color: #ff533b;
    }
  }
}
.icon {
  width: 19.52px;
  height: 16px;
  color: #050505;
}
.title {
  font-size: 28px;
  line-height: 36px;
  margin: 0;
}
.delimiter {
  border-bottom: 1px solid @borderSetting;
}
.subTitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #999999;
}
</style>

<i18n lang="json">{}</i18n>
