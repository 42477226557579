export const RECRUITERS_ROUTE_NAME = 'recruiters';
export const HIRING_MANAGERS_ROUTE_NAME = 'hiring-managers';
export const EMAIL_TEMPLATES_ROUTE_NAME = 'email-templates';
export const IM_TEMPLATES_ROUTE_NAME = 'im-templates';
export const SMS_TEMPLATES_ROUTE_NAME = 'sms-templates';
export const TIME_ON_STATUS_ROUTE_NAME = 'time-on-status';
export const TELEPHONY_ROUTE_NAME = 'telephony';
export const THEME_ROUTE_NAME = 'themes';
export const SECURITY_ROUTE_NAME = 'security';
export const NOTIFICATIONS_ROUTE_NAME = 'notifications';
export const PIPELINE_ROUTE_NAME = 'pipeline';
export const PIPELINE_STATUSES_ROUTE_NAME = 'pipeline-statuses';
export const REJECTION_REASONS_ROUTE_NAME = 'rejection-reasons';
export const ADDITIONAL_DICTIONARIES_ROUTE_NAME = 'additional_dictionaries';
export const ADDITIONAL_DICTIONARIES_INNER_ROUTE_NAME = 'additional_dictionaries_inner';
export const SYSTEM_DIVISIONS_DICTIONARIES_ROUTE_NAME = 'system_division';
export const RESUME_SOURCES_ROUTE_NAME = 'resume-sources';
export const INTERVIEW_TYPES_ROUTE_NAME = 'interview-types';
export const SURVEY_TYPE_A_ROUTE_NAME = 'survey-type-a';
export const VACANCY_REQUEST_ROUTE_NAME = 'vacancy-request';
export const ADDITIONAL_APPLICANT_INFO_ROUTE_NAME = 'additional-applicant-info';
export const SURVEY_TYPE_Q_ROUTE_NAME = 'survey-type-q';
export const SURVEY_TYPE_R_ROUTE_NAME = 'survey-type-r';
export const CAREER_SITE_ROUTE_NAME = 'career-site';
export const COMPANY_ACCOUNT_ROUTE_NAME = 'company-account';
export const DICTIONARY_LIST_ROUTE_NAME = 'dictionary-list';
export const DICTIONARY_ROUTE_NAME = 'dictionary';
export const API_ROUTE_NAME = 'api';
export const VACANCY_ROUTE_NAME = 'vacancy';
