import pickBy from 'lodash/pickBy';
import { getDictionaryId } from '../legacy-schema-utils';
import { getWidget } from './get-widget';
import { getObjectFieldTemplate } from './get-template';

const filterOutUndefineds = (obj) => pickBy(obj, (prop) => prop !== undefined);

export function makeUISchema(field, customGetObjectFieldTemplate) {
  const widget = getWidget(field);
  const objectFieldTemplate = (customGetObjectFieldTemplate || getObjectFieldTemplate)(field);
  const options = filterOutUndefineds({
    breakAfter: field.delimiter,
    name: field.name,
    maxValues: field.max_values,
    dictionaryId: getDictionaryId(field),
    considerHierarchy: field.considerHierarchy,
    multiple: field.multiple,
    disableAutofill: field.disableAutofill,
    onlyLeaf: field.only_leaf,
    maxlength: field.maxlength || field.maxLength, // FFFUUUU
    minlength: field.minlength || field.minLength, // FFFUUUU
    isVisible: field.__isVisible ?? true,
    required: field.required,
    placeholder: field.placeholder
  });
  const uiSchema = {};
  if (field.tooltip_description) {
    uiSchema['ui:description'] = field.tooltip_description;
  }
  if (widget) {
    uiSchema['ui:widget'] = widget;
  }
  if (objectFieldTemplate) {
    uiSchema['ui:ObjectFieldTemplate'] = objectFieldTemplate;
  }
  uiSchema['ui:options'] = options;
  return uiSchema;
}
