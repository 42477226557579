<template>
  <div :class="$style.freebusy" :style="containerStyle">
    <div ref="scrollContainer" :class="$style.scrollContainer" :style="scrollContainerStyle">
      <div :class="$style.dataContainer">
        <div :class="[$style.attendersHeader]">
          <div ref="header" :class="[$style.attendersRow]">
            <div :class="$style.timeCell"></div>
            <template v-if="attenders.length">
              <div
                v-for="j in attenders.length"
                :key="'attenderName' + j"
                :class="[$style.attenderCell, $style.attenderNameCell]"
              >
                {{ attendeesList[j - 1].name }}
              </div>
            </template>
            <div v-else :class="[$style.attenderCell, $style.attenderNameCell]"></div>
          </div>
          <div :class="[$style.attendersRow, $style.attenderCell]">
            <div
              v-for="(busySlot, index) in busyEventsAllDay"
              :key="'busySlot' + index"
              :class="$style.allDaySlot"
              :style="busySlot.style"
            >
              <span :class="$style.busySlotInterval">{{ busySlot.interval }}</span>
            </div>
          </div>
        </div>

        <div v-for="i in 24" :key="i" :class="[$style.attendersRow, $style.attendersBody]">
          <div :class="$style.timeCell" :data-hour="i - 1">{{ i - 1 }}:00</div>
        </div>
        <div
          v-for="(busySlot, index) in busyEventsWithinDay"
          :key="'busySlot' + index"
          :class="$style.busySlot"
          :style="busySlot.style"
        >
          <span :class="$style.busySlotInterval">{{ busySlot.interval }}</span>
        </div>
        <div :class="$style.time" :style="timeStyle"></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
/**
 * Теоретически можно сообразить ещё смёрживание интервалов одного участника
 * при их наложении друг на друга, но кажется это не особо жизненный кейс
 *
 * Также скипнул пересчёт скролла при ресайзе.
 * Пока компонент не в резине - нинад. Потом изян добавить.
 */
import { computed } from 'vue';
import { useStore } from 'vuex';
import { ROW_HEIGHT } from '../config';
import { useBusyEvents, time2y, useScroll, i18nLocal, DateInterval } from '../lib';

/**
 * Участник события.
 * Интерфейс примерный исходя из используемых полей - по факту приходят разные объекты.
 * Уточнить после рефакторинга компонентов сверху.
 */
interface Attendee {
  name: string;
  email: string;
  displayName?: string;
  member?: string;
}

interface Props {
  /**
   * Начало выбранного интервала нового события
   */
  start: Date;
  /**
   * Конец выбранного интервала нового события
   */
  end: Date;
  // TODO: переименовать в attendees для коньсистенции
  attenders: Attendee[];
  busySlots: Record<string, DateInterval[]>;
}

const props = defineProps<Props>();

const { containerStyle, scrollContainerStyle } = useScroll(props.start);
const { busyEventsAllDay, busyEventsWithinDay } = useBusyEvents(props);

const store = useStore();
const account = computed(() => store.getters['config/account']);

const attendeesList = computed(() =>
  props.attenders.map((attendee) => {
    const isMe = attendee.member === account.value.id;
    return {
      ...attendee,
      name: isMe ? i18nLocal('Me') : attendee.displayName || attendee.name || attendee.email
    };
  })
);

const timeStyle = computed(() => {
  const top = time2y(props.start, ROW_HEIGHT);
  const bottom = time2y(props.end, ROW_HEIGHT);
  const height = bottom - top > 0 ? bottom - top : 50;
  return `top: ${top}px; height: ${height}px`;
});
</script>

<style module>
$rowHeight: 50px;
$headerRowHeight: 27px;
$timeCellWidth: 45px;

.freebusy {
  position: relative;
  box-sizing: content-box;
  height: 312px;
  overflow: hidden;
}

.scrollContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
}

.dataContainer {
  display: inline-block;
  position: relative;
  min-width: 100%;
}

.attendersHeader {
  position: sticky;
  top: 0;
  z-index: 3;
}

.attendersRow {
  display: flex;
}

.attenderCell,
.timeCell {
  box-sizing: border-box;
  background: #fff;
  border-bottom: 1px solid $borderColor;
}

.attendersHeader {
  .timeCell,
  .attenderCell {
    height: $headerRowHeight;
  }
}
.attendersRow + .attenderCell {
  width: 100%;
}
.attendersBody .timeCell {
  flex: 1;
  height: $rowHeight;
}

.attendersBody .timeCell[data-hour='23'] {
  border-bottom: none;
}

.attenderNameCell {
  @mixin ellipsis;
  padding-right: 10px;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 14px;
}

.timeCell {
  flex: none;
  flex-basis: $timeCellWidth;
  width: $timeCellWidth;
  font-size: 11px;
}

.attenderCell {
  flex-shrink: 0;
  flex-grow: 1;
  width: 200px;
}

.busySlot {
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
  z-index: 2;
  margin-top: $headerRowHeight;
  margin-left: $timeCellWidth;
  overflow: hidden;
  padding: 2px 5px;
  background: $darkViolet;
}

.allDaySlot {
  position: absolute;
  height: 27px;
  top: 27px;
  left: 0;
  z-index: 2;
  overflow: hidden;
  margin-left: $timeCellWidth;
  padding: 2px 5px;
  background: $darkViolet;
}

.busySlotInterval {
  display: block;
  font-size: 11px;
  line-height: 14px;
  color: white;
}

.time {
  position: absolute;
  left: $timeCellWidth;
  right: 0;
  margin-top: $headerRowHeight;
  z-index: 1;
  background: rgba(34, 187, 210, 0.15);
  border: 5px solid rgba(34, 187, 210, 0.25);
}
</style>

<i18n lang="json">{}</i18n>
