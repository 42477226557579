import { inject } from 'vue';

interface Globals {
  $trlMessage: (
    message: string,
    payload?: Record<string, unknown>,
    defaultValue?: string
  ) => string;
}

/**
 * @deprecated use "useI18N" instead
 * @see src/composables/use-i18n.ts
 */
export function useGlobal(): Globals {
  const $trlMessage = inject<Globals['$trlMessage']>('$trlMessage');
  if (!$trlMessage) {
    throw new Error('$trlMessage не зарегестрирован');
  }
  return {
    $trlMessage
  };
}
