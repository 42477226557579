import { appConfig } from '@/services/config/app-config';
import Poller, { Message } from './libs/poller/poller';
import { WSNotifier } from './libs/notifier/ws-notifier';

export { MessageEvent } from '@/types/poller-message';

if (!window.__POLLER_INSTANCE__) {
  window.__POLLER_INSTANCE__ = new Poller(
    appConfig.get('notifierUrl'),
    appConfig.get('notifierUrl').replace('https', 'wss').replace('http', 'ws')
  );

  if (appConfig.get('account')) {
    window.setTimeout(() => {
      window.__POLLER_INSTANCE__?.start();
    }, 100);
  }
}

if (!window.__WS_NOTIFIER_INSTANCE__) {
  window.__WS_NOTIFIER_INSTANCE__ = new WSNotifier();
}
export const notifier = window.__WS_NOTIFIER_INSTANCE__;
export const poller = window.__POLLER_INSTANCE__;

export const waitForMessage = (fn: (message: Message) => boolean) =>
  new Promise<Message>((resolve) => {
    const releasePoller = poller.onMessage((message) => {
      if (fn(message)) {
        releasePoller();
        resolve(message);
      }
    });
  });
