import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { useTemplateRef, onMounted, watch, onBeforeUnmount } from 'vue';
import { initSnow } from './render-snow';


export default /*@__PURE__*/_defineComponent({
  __name: 'snow-bg',
  props: {
    animated: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;
let snowAnimation: any = null;

watch(
  () => props.animated,
  (value) => {
    if (!snowAnimation) {
      return;
    }
    if (value) {
      snowAnimation.start();
    } else {
      snowAnimation.stop();
    }
  },
  { immediate: true }
);

const canvas = useTemplateRef<HTMLElement>('canvas');

onMounted(() => {
  const resizeObserver = new ResizeObserver((entries) => {
    const rect = entries[0].contentRect;
    if (rect.width > 0) {
      resizeObserver.disconnect();
      snowAnimation = initSnow(canvas.value, rect.width, rect.height);
      if (props.animated) {
        snowAnimation.start();
      }
    }
  });
  if (canvas.value) {
    resizeObserver.observe(canvas.value);
  }
});

onBeforeUnmount(() => {
  snowAnimation?.release();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("canvas", {
    ref_key: "canvas",
    ref: canvas,
    class: _normalizeClass(_ctx.$style.root)
  }, null, 2))
}
}

})