import { appConfig } from '@/services/config/app-config';

import { extractLangMessages } from '@/services/i18n';

export enum ServiceKindCodes {
  brand_offer = 'brand_offer',
  multivacancy = 'multivacancy',
  custom_vacancy_schema = 'custom_vacancy_schema',
  custom_applicant_questionary = 'custom_applicant_questionary',
  vacancy_request = 'vacancy_request',
  vacancy_request_submit = 'vacancy_request_submit',
  survey_type_a = 'survey_type_a',
  survey_type_q = 'survey_type_q',
  survey_type_r = 'survey_type_r',
  read_email_tracking = 'read_email_tracking',
  followups = 'followups',
  schedule_email = 'schedule_email',
  sms = 'sms',
  ip_telephony = 'ip_telephony',
  vcv_integration = 'vcv_integration',
  vacancy_status_groups = 'vacancy_status_groups',
  time_on_state_limit = 'time_on_state_limit',
  time_on_state_report = 'time_on_state_report',
  email_conversion_report = 'email_conversion_report',
  personal_data_request = 'personal_data_request',
  personal_data_custom_email = 'personal_data_custom_email',
  themes = 'themes',
  access_hold = 'access_hold',
  api = 'api',
  additional_dictionaries = 'additional_dictionaries',
  career_site_vacancies = 'career_site_vacancies',
  recruiter_teams = 'recruiter_teams',
  recruiters_division = 'recruiters_division',
  auto_deadline = 'auto_deadline',
  hiring_plan = 'hiring_plan',
  watchers = 'watchers',
  view_applicants_in_reports = 'view_applicants_in_reports',
  calendar_scheduler = 'calendar_scheduler',
  limits = 'limits',
  conference_integration = 'conference_integration',
  applicant_movement_report = 'applicant_movement_report',
  applicant_limit = 'applicant_limit',
  extended_vacancy_workflow = 'extended_vacancy_workflow',
  hide_salary = 'hide_salary',
  recruiter_limit = 'recruiter_limit',
  questionary_constructor = 'questionary_constructor',
  recommendations = 'recommendations',
  applicants_list_view_customize = 'applicants_list_view_customize',
  vacancy_request_schema_limit = 'vacancy_request_schema_limit',
  vacancy_constructor = 'vacancy_fields_constructor',
  pinned_applicant_logs = 'pinned_applicant_logs',
  ai_cold_emails = 'ai_cold_emails'
}

export enum TariffGroupSigns {
  DEMO = 'demo',
  PROLONGATION_ONLY = 'prolongation_only',
  INCREASE_WORKPLACES_FORBIDDEN = 'increase_workplaces_forbidden',
  ARCHIVE = 'archive'
}

export enum LimitTypeCodes {
  active_service_count = 'active_service_count'
}

type Limit = {
  limit_type_code: LimitTypeCodes;
  value: number;
};

export type Tariff = {
  service_kind: {
    code: ServiceKindCodes;
  };
  is_not_created: boolean;
  limits: Limit[];
};

export type TariffGroup = {
  id: number;
  name: string;
  translations: {
    [key: string]: {
      ru_RU: string;
      en_US: string;
    };
  };
  tariffs: Tariff[];
  signs: TariffGroupSigns[];
  level: number;
};

export type License = {
  workplace_limit: number;
  tariff_group_id: TariffGroup['id'];
  tariff_group: TariffGroup;
  tariffs: Tariff[];
  start_at: string | null;
  end_at: string | null;
  sheduled_end_at: string | null;
  // ...tbd
};

type LicenseInfo = {
  previous_license?: License | null;
  current_license?: License | null;
  next_license?: License | null;
};

export function isDemoGroup(tariffGroup: TariffGroup): boolean {
  return tariffGroup.signs.includes(TariffGroupSigns.DEMO);
}

export function isArchive(tariffGroup: TariffGroup): boolean {
  return tariffGroup.signs.includes(TariffGroupSigns.ARCHIVE);
}

export function isDemo(license: License): boolean {
  return license.tariff_group ? isDemoGroup(license.tariff_group) : false;
}

export function getLimit(tariff: Tariff): number | null {
  const activeServiceCountLimit = tariff.limits.find(
    (limit) => limit.limit_type_code === LimitTypeCodes.active_service_count
  );
  if (activeServiceCountLimit) {
    return activeServiceCountLimit.value;
  }

  return null;
}

export class TariffService {
  public licenseInfo: LicenseInfo;
  public tariffGroups: TariffGroup[];
  constructor(licenseInfo: LicenseInfo, tariffGroups: TariffGroup[]) {
    this.licenseInfo = licenseInfo;
    this.tariffGroups = tariffGroups.map((group) => {
      const translations = extractLangMessages(group.translations, appConfig.get('lang'));
      return {
        ...group,
        name: translations.name
      } as TariffGroup;
    });
  }

  public getLimit(tariff: Tariff): number {
    return getLimit(tariff) ?? Infinity;
  }

  public checkLimit(tariff: Tariff, limit: number | null): boolean {
    const tariffLimit = this.getLimit(tariff);

    if (tariffLimit === Infinity) {
      return true;
    }

    if (typeof limit === 'number') {
      return tariffLimit > limit;
    }

    return false;
  }

  public getTariff(kind: ServiceKindCodes, tariffs: Tariff[]) {
    return tariffs.find((t) => t.service_kind.code === kind && t.is_not_created === false);
  }

  public get isDemo(): boolean {
    const licence = this.licenseInfo.current_license || this.licenseInfo.previous_license;
    if (!licence) {
      return false;
    }
    return isDemo(licence);
  }

  public getTariffLimit(kind: ServiceKindCodes): number | null {
    if (!this.licenseInfo.current_license) {
      return null;
    }
    const tariff = this.getTariff(kind, this.licenseInfo.current_license.tariffs);
    return (tariff && this.getLimit(tariff)) ?? null;
  }

  // проверка на доступность по лицензии
  public isTariffAvailable(kind: ServiceKindCodes, limit: number | null = null): boolean {
    if (!this.licenseInfo.current_license) {
      return false;
    }

    const tariff = this.getTariff(kind, this.licenseInfo.current_license.tariffs);

    if (!tariff) {
      return false;
    }

    return this.checkLimit(tariff, limit);
  }

  // проверка на доступность по тарифному плану
  public isAvailable(kind: ServiceKindCodes, limit: number | null = null): boolean {
    const license = this.licenseInfo.current_license;

    if (!license) {
      return false;
    }

    const tariffGroup = this.tariffGroups.find((group) => group.id === license.tariff_group_id);
    if (!tariffGroup) {
      return false;
    }

    const tariff = this.getTariff(kind, tariffGroup.tariffs);

    if (!tariff) {
      return false;
    }

    return this.checkLimit(tariff, limit);
  }

  public getNextAvailableGroup(kind: ServiceKindCodes): TariffGroup | null {
    const license = this.licenseInfo.current_license;

    if (!license) {
      return null;
    }

    if (this.isTariffAvailable(kind) && !this.isDemo) {
      return null;
    }

    const currentTariff = this.getTariff(kind, license.tariffs);
    const nextGroup = this.tariffGroups
      .filter((group) => group.level > license.tariff_group.level)
      .find((group) => {
        const tariff = this.getTariff(kind, group.tariffs);
        if (tariff) {
          if (!currentTariff) {
            return true;
          }
          return this.checkLimit(tariff, this.getLimit(currentTariff));
        }

        return false;
      });

    return nextGroup || null;
  }

  public getUnlimitedGroup(kind: ServiceKindCodes): TariffGroup | null {
    const license = this.licenseInfo.current_license;
    if (!license) {
      return null;
    }
    return (
      this.tariffGroups.find((group) => {
        const tariff = this.getTariff(kind, group.tariffs);
        return (
          tariff && this.getLimit(tariff) === Infinity && group.level > license.tariff_group.level
        );
      }) || null
    );
  }

  public unlimitedTariffGroup(kind: ServiceKindCodes): TariffGroup | false | null {
    const isInLicence = this.isTariffAvailable(kind);
    const isTurnedOff = isInLicence && this.isAvailable(kind);
    return !isInLicence && !isTurnedOff && this.getUnlimitedGroup(kind);
  }
}

declare global {
  interface Window {
    __TARIFFS__: TariffService;
    LicenseInfo?: LicenseInfo;
    TariffGroups?: {
      items: TariffGroup[];
    };
  }
}

// Для более простого тестирования
window.__TARIFFS__ = new TariffService(window.LicenseInfo ?? {}, window.TariffGroups?.items || []);

export default window.__TARIFFS__;
