<template>
  <canvas ref="canvas" :class="$style.root" />
</template>

<script setup lang="ts">
import { useTemplateRef, onMounted, watch, onBeforeUnmount } from 'vue';
import { initSnow } from './render-snow';

const props = defineProps<{ animated: boolean }>();
let snowAnimation: any = null;

watch(
  () => props.animated,
  (value) => {
    if (!snowAnimation) {
      return;
    }
    if (value) {
      snowAnimation.start();
    } else {
      snowAnimation.stop();
    }
  },
  { immediate: true }
);

const canvas = useTemplateRef<HTMLElement>('canvas');

onMounted(() => {
  const resizeObserver = new ResizeObserver((entries) => {
    const rect = entries[0].contentRect;
    if (rect.width > 0) {
      resizeObserver.disconnect();
      snowAnimation = initSnow(canvas.value, rect.width, rect.height);
      if (props.animated) {
        snowAnimation.start();
      }
    }
  });
  if (canvas.value) {
    resizeObserver.observe(canvas.value);
  }
});

onBeforeUnmount(() => {
  snowAnimation?.release();
});
</script>

<style module>
.root {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
</style>

<i18n lang="json">{}</i18n>
