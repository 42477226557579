<template>
  <base-label :class="$style.label">
    <div :class="$style.checkboxContainer">
      <div :class="$style.extraCheckboxIcon">
        <slot name="extra-checkbox-icon"></slot>
      </div>
      <base-checkbox
        :checked="checked"
        :value="value"
        :class="$style.checkbox"
        :disabled="disabled"
        v-bind="$attrs"
        v-on="$listeners"
      />
      <div :class="[$style.customCheckbox, disabled ? $style.disabled : null]">
        <svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.33464 1.33332L2.7513 5.99999L0.667969 3.87878"
            stroke="currentColor"
            stroke-width="1.3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
    <span
      :class="[
        $style.labelContent,
        ellipsis ? $style.labelEllipsisContent : null,
        disabled ? $style.disabled : null
      ]"
      :title="ellipsis ? label : undefined"
      >{{ label }}</span
    >
  </base-label>
</template>

<script>
import BaseLabel from '@/components/label/label.vue';
import BaseCheckbox from '@/components/checkbox/checkbox.vue';

export default {
  name: 'VCheckbox',
  components: {
    BaseLabel,
    BaseCheckbox
  },
  inheritAttrs: false,
  model: {
    prop: 'checked',
    event: 'change-value'
  },
  props: {
    ellipsis: {
      type: Boolean,
      default: false
    },
    checked: {
      type: [Array, Number, String, Boolean],
      default: null
    },
    value: {
      type: [Number, String, Boolean],
      default: ''
    },
    label: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change-value']
};
</script>

<style module>
.label {
  display: flex;
  align-items: flex-start;
  width: max-content;
  color: #050505;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  user-select: none;
}

.checkboxContainer {
  flex: none;
  display: flex;
  height: 24px;
  align-items: center;
  position: relative;
}

.extraCheckboxIcon {
  position: absolute;
  left: -4px;
  top: -6px;
}

.label > .checkboxContainer .checkbox {
  /* TODO: override global styles, remove later */
  cursor: inherit;
  position: absolute;
  opacity: 0;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  z-index: 1;
}
.customCheckbox {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  position: relative;
  flex: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: transparent;
}
.checkbox:checked + .customCheckbox {
  color: $white;
  border-color: $blue-500;
  background: $blue-500;
}

.labelContent {
  margin-left: 8px;
  flex: 1;
}

.labelEllipsisContent {
  @mixin ellipsis;
}

.disabled {
  opacity: 0.3;
}
</style>

<i18n lang="json">{}</i18n>
