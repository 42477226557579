<template>
  <page-layout>
    <template #header-title>
      <back-link href="/">
        {{ $trlMessage('header.applicants_and_vacancies') }}
      </back-link>
    </template>
    <template #content>
      <div :class="$style.cover">
        <div :class="$style.wrapper">
          <base-title :title="title" :sub-title="subTitle" :show-back="!rootRoute" />
          <div v-if="rootRoute" :class="$style.tabs">
            <base-tabs :pages="orgPages" :is-orgs="true" />
            <base-delimiter top="0" bottom="10" />
            <base-tabs :pages="pages" />
          </div>
          <router-view />
        </div>
      </div>
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/page-layout/page-layout.vue';
import BackLink from '@/components/page-layout/back-link.vue';
import BaseTitle from './base-title.vue';
import BaseTabs from './base-tabs.vue';
import BaseDelimiter from '@/components/delimiter/delimiter';
import routesInfo from './constants';

export default {
  name: 'SettingsApp',
  components: { PageLayout, BackLink, BaseTitle, BaseTabs, BaseDelimiter },
  props: {
    pages: {
      type: Array,
      required: true
    },
    orgPages: {
      type: Array,
      required: true
    }
  },
  computed: {
    title() {
      return this.rootRoute ? this.$i18n('settings.title') : routesInfo[this.$route.name].title;
    },
    rootRoute() {
      return this.$route.path === '/';
    },
    subTitle() {
      return this.rootRoute ? null : this.$i18n('settings.subTitle');
    }
  }
};
</script>

<style module lang="less">
@import '~@less/common/mixins/font';
@import '~@less/common/variables';

.wrapper {
  padding: 0 15px;
  max-width: 90%;
  position: relative;

  composes: col-xs-offset-2 col-xs-9 from global;
}
.cover {
  background-color: @white;
  border-radius: 16px;
  margin: 8px;
  flex: 1;
}
.tabs {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-bottom: 40px;
  min-width: 740px;
  max-width: 1120px;
}
.drop {
  width: 755px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 16px 32px 32px 32px;
}
</style>

<i18n lang="json">
{
  "settings.title": {
    "ru_RU": "Настройки Хантфлоу",
    "en_US": "Huntflow settings"
  },
  "settings.subTitle": {
    "ru_RU": "Мой Хантфлоу",
    "en_US": "My Huntflow"
  }
}
</i18n>
