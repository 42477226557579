import { createWebHistory, createRouter as createRouterBase } from '@/router/router';
import pageTitle from '@/services/page-title';
import { getAvailableRoutes, getAvailableNavigations } from './routes';
import {
  COMPANY_ACCOUNT_ROUTE_NAME,
  NOTIFICATIONS_ROUTE_NAME,
  PIPELINE_ROUTE_NAME
} from './constants';

const specialRedirects = {
  notify: [NOTIFICATIONS_ROUTE_NAME],
  settingsMain: [COMPANY_ACCOUNT_ROUTE_NAME],
  pipelines: [PIPELINE_ROUTE_NAME]
};

export function createRouter(base) {
  const router = createRouterBase({
    history: createWebHistory(base),
    routes: getAvailableRoutes()
  });

  router.beforeEach((to, from, next) => {
    const name = to.hash.replace('#', '');
    if (!name) {
      pageTitle.replace(to.meta.text);
      return next();
    }

    next({ path: `/${specialRedirects[name] || name}` });
  });

  return router;
}

export function getPages() {
  return getAvailableNavigations();
}
