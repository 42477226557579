<template>
  <modal-wrapper v-model="showModal">
    <template #default="{ dismiss, setDirty }">
      <div v-bind="$attrs" :class="$style.vacancyRequestForm">
        <header-block :title="title">
          <template #default="{ titleClass, title }">
            <div :class="$style.header">
              <span :class="[titleClass, $style.title]">
                {{ title }}
              </span>
              <link-button
                v-if="!isWatcher"
                size="xs"
                :to="settingsHref"
                target="_blank"
                icon="options-2-18"
              >
                {{ $i18n('go_to_constructor') }}
              </link-button>
            </div>
          </template>
        </header-block>
        <vjsf-form
          ref="vjsf"
          :force-arhive-hidding="isNew"
          :schema="vacancyRequestSchema.schema"
          :ui-schema="uiSchema"
          :form-data="initialFormData"
          :class="$style.form"
          @submit="handleSubmit"
          @dirty="setDirty"
        />
        <portal :to="mainPortal">
          <file-attachment-list v-model="files" :class="$style.attachments" />
        </portal>
        <portal v-if="showAttendees" :to="sidePortal">
          <base-delimiter v-if="hasApplicantsToHire" :top="20" :bottom="20" />
          <attendee-list
            ref="attendeeList"
            v-model="attendees"
            :invalid="showAttendeeInvalid"
            :invalid-value-indices="invalidAttendeeIndices"
            :required="vacancyRequestSchema.attendee_required"
            :hint="vacancyRequestSchema.attendee_hint"
            @typed-email="handleTypedEmail"
            @invalid="handleAttendeeInvalid"
          />
        </portal>
        <layout-row border-before :class="$style.footer">
          <buttons-line>
            <base-button
              :loading="loading"
              data-qa="save"
              color="black"
              @click="handleSubmitButton"
            >
              {{ $trlMessage('button.save') }}
            </base-button>
            <base-button data-qa="cancel" @click="dismiss">
              {{ $trlMessage('button.cancel') }}
            </base-button>
          </buttons-line>
        </layout-row>
      </div>
    </template>
  </modal-wrapper>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { Portal } from 'portal-vue';
import { userPermissions } from '@/services/config/user-permissions';
import vacancyRequestAPI from '@/api/vacancy-request';
import HeaderBlock from '@/components/layout/header';
import LayoutRow from '@/components/layout/row';
import ButtonsLine from '@/components/layout/buttons-line';
import ModalWrapper from '@/components/modal-next/modal-wrapper';
import BaseButton from '@/components/button/button';
import BaseDelimiter from '@/components/delimiter/delimiter';
import { separateSystemFields } from '@/components/vjsf/helpers/separate-system-fields';
import { setObjectTemplateComponents } from '@/components/vjsf/helpers/set-object-template-components';
import FileAttachmentList from '@/components/ui/file-attachment-list/file-attachment-list';
import AttendeeList from './attendee-list';

import VacancyRequestFormLayout, {
  VACANCY_REQUEST_MAIN_PORTAL,
  VACANCY_REQUEST_SIDE_PORTAL,
  getGrouppedFields
} from '../../components/vacancy-request-form-layout';
import LinkButton from '@/components/button/link-button.vue';

export default {
  name: 'RequestForm',
  components: {
    AttendeeList,
    BaseButton,
    BaseDelimiter,
    ButtonsLine,
    FileAttachmentList,
    HeaderBlock,
    LayoutRow,
    ModalWrapper,
    Portal,
    VjsfForm: defineAsyncComponent(() => import('@/components/vjsf/vjsf-form')),
    LinkButton
  },
  inheritAttrs: false,
  model: {
    prop: 'open',
    event: 'change'
  },
  valuesSource: {},
  props: {
    open: Boolean,
    vacancyRequestSchema: {
      type: Object,
      required: true
    },
    vacancyRequest: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['change', 'updated', 'created'],
  data() {
    return {
      loading: false,
      submitted: false,
      files: [],
      attendees: [],
      typedAttendee: null,
      attendeeInvalid: false,
      serverError: null
    };
  },
  computed: {
    settingsHref: () => `/settings/orgs/${userPermissions.get('nick')}/vacancy-request`,
    mainPortal: () => VACANCY_REQUEST_MAIN_PORTAL,
    sidePortal: () => VACANCY_REQUEST_SIDE_PORTAL,
    uiSchema() {
      const result = {
        ...setObjectTemplateComponents(this.vacancyRequestSchema.ui_schema),
        'ui:ObjectFieldTemplate': VacancyRequestFormLayout
      };
      // нужно удалить разделитель у поля, если он есть,
      // т.к. он рисуется отдельно в зависимости от включённости согласования
      //
      if (result['applicants_to_hire']) {
        result['applicants_to_hire']['ui:options'].size = 'micro';
      }

      // Между системными и несистемными нужно ставить разделитель.
      // Но разделителя не должно быть, если системное поле последнее в "зоне"
      const zones = getGrouppedFields(result['ui:order']);
      separateSystemFields(this.vacancyRequestSchema.schema, result, zones);
      return result;
    },
    // TODO: удалить после публичного релиза конструктора заявок
    hasApplicantsToHire() {
      return Boolean(this.uiSchema['applicants_to_hire']);
    },
    invalidAttendeeIndices() {
      /* ошибка от бэка приходит в формате
       * {
       *   attendees: {
       *     0: { ... }
       *     2: { ... }
       *   }
       * }
       */
      const serverAttendeesErrors = this.serverError?.attendees;
      if (!serverAttendeesErrors) {
        return [];
      }
      const result = Object.keys(serverAttendeesErrors).map(Number);
      if (result.length) {
        this.$refs.attendeeList.focus();
      }
      return result;
    },
    initialFormData() {
      return this.vacancyRequest.values ?? {};
    },
    showModal: {
      get() {
        return this.open;
      },
      set(flag) {
        this.$emit('change', flag);
      }
    },
    title() {
      return `${this.$trlMessage('vacancy_request')} ${this.vacancyRequestSchema.name}`;
    },
    showAttendees() {
      return this.isNew && [true, false].includes(this.vacancyRequestSchema.attendee_required);
    },
    isNew() {
      return !this.vacancyRequest.id;
    },
    showAttendeeInvalid() {
      return this.submitted && this.attendeeInvalid;
    },
    isWatcher() {
      return userPermissions.isWatcher;
    }
  },
  watch: {
    vacancyRequest: {
      immediate: true,
      handler({ files, states }) {
        this.loading = false;
        this.submitted = false;
        this.typedAttendee = null;
        this.attendeeInvalid = false;

        this.files = (files ?? []).map((file) => ({
          ...file
        }));

        this.attendees = (states ?? [])
          .map((item) => ({ email: item.email, displayName: item.email, order: item.order }))
          .sort((a, b) => a.order - b.order);
      }
    }
  },
  methods: {
    handleSubmitButton() {
      this.submitted = true;
      this.$refs.vjsf.submit();
    },
    handleSubmit({ formData }) {
      this.submitted = true;
      if (!this.checkAdditionalFields()) {
        return;
      }

      formData.account_vacancy_request = this.vacancyRequestSchema.id;
      formData = this.reachedFormData(formData);

      this.serverError = null;
      this.loading = true;
      const action = this.isNew ? this.handleCreate : this.handleUpdate;
      action(formData).finally(() => {
        this.loading = false;
        this.files = [];
      });
    },
    checkAdditionalFields() {
      if (this.attendeeInvalid) {
        this.$refs.attendeeList.focus();
        return false;
      }
      return true;
    },
    reachedFormData(formData) {
      const data = Object.assign({}, formData);
      if (this.showAttendees) {
        data.attendees = this.attendees;
        if (this.typedAttendee) {
          data.attendees.push({ email: this.typedAttendee });
        }
      }

      data.files = this.files.map(({ id }) => id);
      return data;
    },
    handleUpdate(formData) {
      return vacancyRequestAPI
        .update(this.vacancyRequest.id, formData)
        .then((vacancyRequest) => {
          this.$emit('updated', vacancyRequest);
        })
        .catch((err) => {
          this.serverError = err.response.data;
        });
    },
    handleCreate(formData) {
      return vacancyRequestAPI
        .create(formData)
        .then((vacancyRequest) => {
          this.$emit('created', vacancyRequest);
        })
        .catch((err) => {
          this.serverError = err.response.data;
        });
    },
    handleTypedEmail(email) {
      this.typedAttendee = email;
    },
    handleAttendeeInvalid(flag) {
      this.attendeeInvalid = flag;
    }
  }
};
</script>

<i18n lang="json">
{
  "go_to_constructor": {
    "ru_RU": "Настроить поля формы",
    "en_US": "Customise form fields"
  }
}
</i18n>

<style module>
.vacancyRequestForm {
  background-color: #fff;
  border-radius: 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  gap: 8px;
  min-width: 0;

  .title {
    @mixin ellipsis;
  }
}

/*
 * иллюстрация почему сова-селектор - хорошо,
 * а безусловные маржины - нет
 */
.form {
  & > :global(.form-group) {
    margin: 0;
  }
}

.footer {
  padding: 20px 0;
}

.attachments {
  margin-top: 10px;
}
</style>
