import { DateInterval } from '../lib';

/**
 * Возвращает даты начала и конца выбранного дня для нового события
 * @param selectedDateStart Начало события (конец может быть на следующем дне, чтобы точно рассчитать текущий день)
 */
export const getDayInterval = (selectedDateStart: Date): DateInterval => {
  const start = new Date(selectedDateStart);
  start.setHours(0, 0, 0, 0);
  const end = new Date(selectedDateStart);
  end.setHours(23, 59, 59, 999);
  return { start, end };
};
