import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["data-hour"]

import { computed } from 'vue';
import { useStore } from 'vuex';
import { ROW_HEIGHT } from '../config';
import { useBusyEvents, time2y, useScroll, i18nLocal, DateInterval } from '../lib';

/**
 * Участник события.
 * Интерфейс примерный исходя из используемых полей - по факту приходят разные объекты.
 * Уточнить после рефакторинга компонентов сверху.
 */
interface Attendee {
  name: string;
  email: string;
  displayName?: string;
  member?: string;
}

interface Props {
  /**
   * Начало выбранного интервала нового события
   */
  start: Date;
  /**
   * Конец выбранного интервала нового события
   */
  end: Date;
  // TODO: переименовать в attendees для коньсистенции
  attenders: Attendee[];
  busySlots: Record<string, DateInterval[]>;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'freebusy',
  props: {
    start: {},
    end: {},
    attenders: {},
    busySlots: {}
  },
  setup(__props: any) {

/**
 * Теоретически можно сообразить ещё смёрживание интервалов одного участника
 * при их наложении друг на друга, но кажется это не особо жизненный кейс
 *
 * Также скипнул пересчёт скролла при ресайзе.
 * Пока компонент не в резине - нинад. Потом изян добавить.
 */
const props = __props;

const { containerStyle, scrollContainerStyle } = useScroll(props.start);
const { busyEventsAllDay, busyEventsWithinDay } = useBusyEvents(props);

const store = useStore();
const account = computed(() => store.getters['config/account']);

const attendeesList = computed(() =>
  props.attenders.map((attendee) => {
    const isMe = attendee.member === account.value.id;
    return {
      ...attendee,
      name: isMe ? i18nLocal('Me') : attendee.displayName || attendee.name || attendee.email
    };
  })
);

const timeStyle = computed(() => {
  const top = time2y(props.start, ROW_HEIGHT);
  const bottom = time2y(props.end, ROW_HEIGHT);
  const height = bottom - top > 0 ? bottom - top : 50;
  return `top: ${top}px; height: ${height}px`;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.freebusy),
    style: _normalizeStyle(_unref(containerStyle))
  }, [
    _createElementVNode("div", {
      ref: "scrollContainer",
      class: _normalizeClass(_ctx.$style.scrollContainer),
      style: _normalizeStyle(_unref(scrollContainerStyle))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.dataContainer)
      }, [
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.$style.attendersHeader])
        }, [
          _createElementVNode("div", {
            ref: "header",
            class: _normalizeClass([_ctx.$style.attendersRow])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.timeCell)
            }, null, 2),
            _cache[0] || (_cache[0] = _createTextVNode()),
            (_ctx.attenders.length)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.attenders.length, (j) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: 'attenderName' + j,
                    class: _normalizeClass([_ctx.$style.attenderCell, _ctx.$style.attenderNameCell])
                  }, _toDisplayString(attendeesList.value[j - 1].name), 3))
                }), 128))
              : (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass([_ctx.$style.attenderCell, _ctx.$style.attenderNameCell])
                }, null, 2))
          ], 2),
          _cache[1] || (_cache[1] = _createTextVNode()),
          _createElementVNode("div", {
            class: _normalizeClass([_ctx.$style.attendersRow, _ctx.$style.attenderCell])
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(busyEventsAllDay), (busySlot, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: 'busySlot' + index,
                class: _normalizeClass(_ctx.$style.allDaySlot),
                style: _normalizeStyle(busySlot.style)
              }, [
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.$style.busySlotInterval)
                }, _toDisplayString(busySlot.interval), 3)
              ], 6))
            }), 128))
          ], 2)
        ], 2),
        _cache[2] || (_cache[2] = _createTextVNode()),
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(24, (i) => {
          return _createElementVNode("div", {
            key: i,
            class: _normalizeClass([_ctx.$style.attendersRow, _ctx.$style.attendersBody])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.timeCell),
              "data-hour": i - 1
            }, _toDisplayString(i - 1) + ":00", 11, _hoisted_1)
          ], 2)
        }), 64)),
        _cache[3] || (_cache[3] = _createTextVNode()),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(busyEventsWithinDay), (busySlot, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: 'busySlot' + index,
            class: _normalizeClass(_ctx.$style.busySlot),
            style: _normalizeStyle(busySlot.style)
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.$style.busySlotInterval)
            }, _toDisplayString(busySlot.interval), 3)
          ], 6))
        }), 128)),
        _cache[4] || (_cache[4] = _createTextVNode()),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.time),
          style: _normalizeStyle(timeStyle.value)
        }, null, 6)
      ], 2)
    ], 6)
  ], 6))
}
}

})