import { ALL_DAY_ROW_HEIGHT } from '../config';

/**
 * Преобразует время в вертикальную координату.
 * Учитывает ALL_DAY_ROW_HEIGHT - высоту ячейки задач на весь день сверху
 *
 * @param date - Дата и время для преобразования.
 * @param oneHourHeight - Высота, соответствующая одному часу на графике.
 * @returns Вертикальная координата в пикселях.
 */
export const time2y = (date: Date, oneHourHeight: number): number => {
  const hoursSinceDayStart = date.getHours() + date.getMinutes() / 60;
  return hoursSinceDayStart * oneHourHeight + ALL_DAY_ROW_HEIGHT;
};
