<template>
  <view-field
    :schema="schema"
    :is-visible="options.isVisible"
    :label="label"
    :delimiter="options.breakAfter"
    :data-qa="options.name || id"
  >
    <widget-value :value="viewValue" />
  </view-field>
</template>

<script>
import { getMethodsById } from '@/api/dictionary-partial';
import { VacancyNamingHelper } from '@/util/vacancy-naming';
import ViewField from './view-field';
import WidgetValue from './base-widget/widget-value';

export default {
  name: 'ViewFieldPartialDictionary',
  components: {
    ViewField,
    WidgetValue
  },
  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: [Number, String, Array],
      default: undefined
    },
    label: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      required: true
    },
    schema: ViewField.props.schema
  },
  data() {
    return {
      viewValue: ''
    };
  },
  mounted() {
    if (!this.value || (Array.isArray(this.value) && !this.value.length)) {
      this.viewValue = '';
      return;
    }

    this.viewValue = this.$trlMessage('Loading');
    const values = Array.isArray(this.value) ? this.value : [this.value];

    const { getFullNames } = getMethodsById(this.options.dictionaryId);

    getFullNames(values).then((namesMap) => {
      this.viewValue = Object.values(namesMap)
        .map((names) => {
          return names.join(VacancyNamingHelper.DIVIDER);
        })
        .join(', ');
    });
  }
};
</script>

<i18n lang="json">{}</i18n>
