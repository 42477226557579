import {
  API_ROUTE_NAME,
  CAREER_SITE_ROUTE_NAME,
  COMPANY_ACCOUNT_ROUTE_NAME,
  DICTIONARY_ROUTE_NAME,
  EMAIL_TEMPLATES_ROUTE_NAME,
  IM_TEMPLATES_ROUTE_NAME,
  NOTIFICATIONS_ROUTE_NAME,
  PIPELINE_ROUTE_NAME,
  RECRUITERS_ROUTE_NAME,
  REJECTION_REASONS_ROUTE_NAME,
  RESUME_SOURCES_ROUTE_NAME,
  SECURITY_ROUTE_NAME,
  SMS_TEMPLATES_ROUTE_NAME,
  SURVEY_TYPE_A_ROUTE_NAME,
  ADDITIONAL_APPLICANT_INFO_ROUTE_NAME,
  SURVEY_TYPE_Q_ROUTE_NAME,
  SURVEY_TYPE_R_ROUTE_NAME,
  PIPELINE_STATUSES_ROUTE_NAME,
  TELEPHONY_ROUTE_NAME,
  THEME_ROUTE_NAME,
  INTERVIEW_TYPES_ROUTE_NAME,
  VACANCY_REQUEST_ROUTE_NAME,
  TIME_ON_STATUS_ROUTE_NAME,
  HIRING_MANAGERS_ROUTE_NAME,
  ADDITIONAL_DICTIONARIES_ROUTE_NAME,
  SYSTEM_DIVISIONS_DICTIONARIES_ROUTE_NAME,
  ADDITIONAL_DICTIONARIES_INNER_ROUTE_NAME,
  VACANCY_ROUTE_NAME
} from './constants';
import { MemberType } from '@/types/member-type';
import { userPermissions } from '@/services/config/user-permissions';
import { telephony } from '@/services/telephony/telephony';
import { PageHelper } from '@/pages/helper';
import NotFound from '@/components/not-found/index.vue';
import tariffs, { ServiceKindCodes } from '@/services/tariffs';
import { appConfig } from '@/services/config/app-config';
import { AnalyticsAPI } from '@/api/analytics';
import VueVoid from '@/components/vue-void.vue';

const orgRoutes = [
  // company account
  {
    name: 'company',
    path: '/company',
    redirect: userPermissions.isManager
      ? { name: SYSTEM_DIVISIONS_DICTIONARIES_ROUTE_NAME }
      : { name: COMPANY_ACCOUNT_ROUTE_NAME },
    component: () => PageHelper.lazyLoadView(import('@/pages/simple-wrapper.vue')),
    meta: {
      access: {
        [MemberType.MANAGER]: true
      }
    },
    children: [
      {
        name: COMPANY_ACCOUNT_ROUTE_NAME,
        path: '/company-account',
        component: () => PageHelper.lazyLoadView(import('../pages/company-account')),
        meta: {}
      },
      {
        name: SYSTEM_DIVISIONS_DICTIONARIES_ROUTE_NAME,
        path: '/dictionary-list/account_division',
        component: () =>
          PageHelper.lazyLoadView(
            import('../pages/dictionaries/dictionary-detail/dictionary-detail')
          ),
        props: {
          dictionaryId: 'account_division'
        },
        meta: {
          fullPage: true,
          access: {
            [MemberType.MANAGER]: true
          }
        }
      },
      {
        name: SECURITY_ROUTE_NAME,
        path: '/security',
        component: () => PageHelper.lazyLoadView(import('../pages/limits/limits-page')),
        meta: {
          isLocked: !tariffs.isTariffAvailable(ServiceKindCodes.limits)
        },
        beforeEnter: (to, from, next) => {
          AnalyticsAPI.send('tariffbased_feature_click', {
            feature_type: 'settings_nav_access'
          });
          next();
        }
      },
      {
        name: TELEPHONY_ROUTE_NAME,
        path: '/telephony',
        component: () => PageHelper.lazyLoadView(import('../pages/telephony')),
        meta: {
          access: {
            [MemberType.MANAGER]: true
          }
        }
      },
      {
        name: NOTIFICATIONS_ROUTE_NAME,
        path: '/notifications',
        component: () => PageHelper.lazyLoadView(import('../pages/notifications')),
        meta: {
          access: {
            [MemberType.MANAGER]: true,
            [MemberType.OWNER]: true
          }
        }
      }
    ]
  },
  {
    name: NOTIFICATIONS_ROUTE_NAME,
    path: '/notifications',
    component: () => PageHelper.lazyLoadView(import('../pages/notifications')),
    children: [],
    meta: {
      access: {
        [MemberType.WATCHER]: true
      }
    }
  },
  // recruiters
  {
    name: 'users',
    path: '/users',
    redirect: { name: RECRUITERS_ROUTE_NAME },
    component: () => PageHelper.lazyLoadView(import('@/pages/simple-wrapper.vue')),
    meta: {
      fullPage: true,
      access: {
        [MemberType.MANAGER]: true
      }
    },
    children: [
      {
        name: RECRUITERS_ROUTE_NAME,
        path: '/recruiters',
        component: () => PageHelper.lazyLoadView(import('../pages/recrutiers/recruiters')),
        props: (route) => route.query,
        meta: {
          fullPage: true,
          access: {
            [MemberType.MANAGER]: true
          }
        }
      },
      {
        name: HIRING_MANAGERS_ROUTE_NAME,
        path: '/hiring-managers',
        component: () =>
          PageHelper.lazyLoadView(import('../pages/hiring-managers/hiring-managers')),
        props: (route) => route.query,
        meta: {
          fullPage: true,
          access: {
            [MemberType.MANAGER]: true
          }
        }
      }
    ]
  },
  // career-site
  {
    name: 'response',
    path: '/response',
    redirect: { name: CAREER_SITE_ROUTE_NAME },
    component: () => PageHelper.lazyLoadView(import('@/pages/simple-wrapper.vue')),
    meta: {
      access: {
        [MemberType.MANAGER]: true
      }
    },
    children: [
      {
        name: CAREER_SITE_ROUTE_NAME,
        path: '/career-site',
        component: () => PageHelper.lazyLoadView(import('../pages/career-site')),
        meta: {
          access: {
            [MemberType.MANAGER]: true
          }
        }
      }
    ]
  },
  // resume-sources
  {
    name: 'business',
    path: '/business',
    redirect: { name: VACANCY_REQUEST_ROUTE_NAME },
    component: () => PageHelper.lazyLoadView(import('@/pages/simple-wrapper.vue')),
    meta: {
      access: {
        [MemberType.MANAGER]: true
      }
    },
    children: [
      {
        name: VACANCY_REQUEST_ROUTE_NAME,
        path: '/vacancy-request',
        component: () =>
          PageHelper.lazyLoadView(import('../pages/vacancy-request/vacancy-request')),
        meta: {
          isLocked: !tariffs.isTariffAvailable(ServiceKindCodes.vacancy_request_schema_limit, 0),
          fullPage: true,
          access: {
            [MemberType.MANAGER]: true
          }
        }
      },
      {
        name: VACANCY_ROUTE_NAME,
        path: '/vacancy',
        component: () => PageHelper.lazyLoadView(import('../pages/vacancy/vacancy')),
        meta: {
          isLocked: !tariffs.isTariffAvailable(ServiceKindCodes.vacancy_constructor),
          access: {
            [MemberType.MANAGER]: true
          }
        }
      },
      {
        name: ADDITIONAL_APPLICANT_INFO_ROUTE_NAME,
        path: '/additional-applicant-info',
        component: () =>
          PageHelper.lazyLoadView(
            import('../pages/additional-applicant-info/additional-applicant-info')
          ),
        meta: {
          isLocked: !tariffs.isTariffAvailable(ServiceKindCodes.questionary_constructor),
          access: {
            [MemberType.MANAGER]: true
          }
        }
      },
      {
        name: ADDITIONAL_DICTIONARIES_ROUTE_NAME,
        path: '/additional-dictionary-list',
        redirect: { name: ADDITIONAL_DICTIONARIES_INNER_ROUTE_NAME },
        component: () => PageHelper.lazyLoadView(import('@/pages/simple-wrapper.vue')),
        meta: {
          isLocked: !tariffs.isTariffAvailable(ServiceKindCodes.additional_dictionaries),
          access: {
            [MemberType.MANAGER]: true
          }
        },
        beforeEnter: (to, from, next) => {
          AnalyticsAPI.send('tariffbased_feature_click', {
            feature_type: 'settings_nav_access'
          });
          next();
        },
        children: [
          {
            name: ADDITIONAL_DICTIONARIES_INNER_ROUTE_NAME,
            path: '',
            component: () =>
              PageHelper.lazyLoadView(
                import('../pages/dictionaries/dictionary-list/add-dictionary-page.vue')
              ),
            meta: {
              fullPage: true
            }
          },
          {
            name: DICTIONARY_ROUTE_NAME,
            path: ':dictionaryId',
            component: () =>
              PageHelper.lazyLoadView(
                import('../pages/dictionaries/dictionary-detail/dictionary-detail.vue')
              ),
            props: true,
            meta: {
              fullPage: true
            }
          }
        ]
      },
      {
        name: RESUME_SOURCES_ROUTE_NAME,
        path: '/resume-sources',
        component: () => PageHelper.lazyLoadView(import('../pages/sources/sources')),
        meta: {}
      },
      {
        name: INTERVIEW_TYPES_ROUTE_NAME,
        path: '/interview-types',
        component: () =>
          PageHelper.lazyLoadView(import('../pages/interview-types/interview-types')),
        meta: {}
      }
    ]
  },

  // pipeline
  {
    name: 'funnel',
    path: '/funnel',
    redirect: { name: PIPELINE_ROUTE_NAME },
    component: () => PageHelper.lazyLoadView(import('@/pages/simple-wrapper.vue')),
    meta: {},
    children: [
      {
        name: PIPELINE_ROUTE_NAME,
        path: '/pipeline',
        components: {
          default: () => PageHelper.lazyLoadView(import('../pages/pipelines/pipelines'))
        },
        meta: {}
      },
      {
        name: PIPELINE_STATUSES_ROUTE_NAME,
        path: '/pipeline-statuses',
        components: {
          default: () => PageHelper.lazyLoadView(import('../pages/pipelines/pipeline-statuses')),
          extra: appConfig.blogAvailable
            ? () => PageHelper.lazyLoadView(import('../pages/pipelines-extra'))
            : VueVoid
        },
        meta: {
          isLocked: !tariffs.isTariffAvailable(ServiceKindCodes.vacancy_status_groups, 0)
        }
      },
      {
        name: TIME_ON_STATUS_ROUTE_NAME,
        path: '/time-on-status',
        component: () => PageHelper.lazyLoadView(import('../pages/time-on-status/time-on-status')),
        meta: {
          isLocked: !tariffs.isTariffAvailable(ServiceKindCodes.time_on_state_limit)
        },
        beforeEnter: (to, from, next) => {
          AnalyticsAPI.send('tariffbased_feature_click', {
            feature_type: 'settings_nav_access'
          });
          next();
        }
      },
      {
        name: REJECTION_REASONS_ROUTE_NAME,
        path: '/rejection-reasons',
        component: () =>
          PageHelper.lazyLoadView(import('../pages/rejection-reasons/rejection-reasons')),
        meta: {}
      }
    ]
  },
  // api
  {
    name: API_ROUTE_NAME,
    path: '/api',
    component: () => PageHelper.lazyLoadView(import('../pages/api')),
    meta: {
      isLocked: !tariffs.isTariffAvailable(ServiceKindCodes.api)
    },
    children: [],
    beforeEnter: (to, from, next) => {
      AnalyticsAPI.send('tariffbased_feature_click', {
        feature_type: 'settings_nav_access'
      });
      next();
    }
  },
  // survey
  {
    name: 'survey',
    path: '/survey',
    redirect: { name: EMAIL_TEMPLATES_ROUTE_NAME },
    component: () => PageHelper.lazyLoadView(import('@/pages/simple-wrapper.vue')),
    meta: {
      access: {
        [MemberType.MANAGER]: true
      }
    },
    children: [
      {
        name: EMAIL_TEMPLATES_ROUTE_NAME,
        path: '/email-templates',
        component: () => PageHelper.lazyLoadView(import('../pages/mail-templates/mail-templates')),
        meta: {
          access: {
            [MemberType.MANAGER]: true
          }
        }
      },
      {
        name: IM_TEMPLATES_ROUTE_NAME,
        path: '/im-templates',
        component: () => PageHelper.lazyLoadView(import('../pages/im-templates/im-templates')),
        meta: {
          access: {
            [MemberType.MANAGER]: true
          }
        }
      },
      {
        name: SMS_TEMPLATES_ROUTE_NAME,
        path: '/sms-templates',
        component: () => PageHelper.lazyLoadView(import('../pages/sms-templates/sms-templates')),
        meta: {
          isLocked: !tariffs.isTariffAvailable(ServiceKindCodes.sms),
          access: {
            [MemberType.MANAGER]: true
          }
        },
        beforeEnter: (to, from, next) => {
          AnalyticsAPI.send('tariffbased_feature_click', {
            feature_type: 'settings_nav_access'
          });
          next();
        }
      },
      {
        name: SURVEY_TYPE_Q_ROUTE_NAME,
        path: '/survey-type-q',
        component: () => PageHelper.lazyLoadView(import('../pages/survey-type-q/survey-type-q')),
        meta: {
          isLocked: !tariffs.isTariffAvailable(ServiceKindCodes.survey_type_q),
          access: {
            [MemberType.MANAGER]: true
          }
        },
        beforeEnter: (to, from, next) => {
          AnalyticsAPI.send('tariffbased_feature_click', {
            feature_type: 'settings_nav_access'
          });
          next();
        }
      },
      {
        name: SURVEY_TYPE_A_ROUTE_NAME,
        path: '/survey-type-a',
        component: () => PageHelper.lazyLoadView(import('../pages/survey-type-a/survey-type-a')),
        meta: {},
        beforeEnter: (to, from, next) => {
          AnalyticsAPI.send('tariffbased_feature_click', {
            feature_type: 'settings_nav_access'
          });
          next();
        }
      },
      {
        name: SURVEY_TYPE_R_ROUTE_NAME,
        path: '/survey-type-r',
        component: () => PageHelper.lazyLoadView(import('../pages/survey-type-r/survey-type-r')),
        meta: {
          isLocked: !tariffs.isTariffAvailable(ServiceKindCodes.survey_type_r),
          access: {
            [MemberType.MANAGER]: true
          }
        },
        beforeEnter: (to, from, next) => {
          AnalyticsAPI.send('tariffbased_feature_click', {
            feature_type: 'settings_nav_access'
          });
          next();
        }
      }
    ]
  },
  //themes
  {
    name: THEME_ROUTE_NAME,
    path: '/themes',
    component: () => PageHelper.lazyLoadView(import('../pages/themes/themes')),
    meta: {
      parent: 'themes',
      isLocked: !tariffs.isTariffAvailable(ServiceKindCodes.themes),
      access: {
        [MemberType.MANAGER]: true,
        [MemberType.WATCHER]: true
      }
    },
    children: [],
    beforeEnter: (to, from, next) => {
      AnalyticsAPI.send('tariffbased_feature_click', {
        feature_type: 'settings_nav_access'
      });
      next();
    }
  }
];

export default orgRoutes;

const spaces = {
  [NOTIFICATIONS_ROUTE_NAME]: true
};

const memberType = userPermissions.get('member_type');

function filterAvailable(routes) {
  return routes.filter(({ name, meta = {} }) => {
    if (
      name === TELEPHONY_ROUTE_NAME &&
      !['MANGO', 'ASTERISK'].includes(telephony.integrationType)
    ) {
      return false;
    }
    if (name === NOTIFICATIONS_ROUTE_NAME && !meta.access?.[memberType]) {
      return false;
    }

    if (userPermissions.isOwner) {
      return true;
    }

    return Boolean(meta.access?.[memberType]);
  });
}

export function getAvailableNavigations() {
  return filterAvailable(orgRoutes).map(({ name, meta, children }) => ({
    name,
    to: { name },
    spaceAfter: !!spaces[name],
    isLocked: meta?.isLocked,
    children: filterAvailable(children)
  }));
}

export function getAvailableRoutes() {
  const routes = filterAvailable(orgRoutes);
  try {
    routes.unshift({
      path: '/',
      redirect: { name: routes[0].name }
    });
  } catch (error) {
    console.log(error);
  } finally {
    routes.push({
      path: '/:pathMatch(.*)*',
      component: NotFound
    });
  }
  return routes;
}
