import { createWebHistory, createRouter as createRouterBase } from '@/router/router';
import pageTitle from '@/services/page-title';
import routes from './routes';

export function createRouter(base) {
  const router = createRouterBase({
    history: createWebHistory(base),
    routes
  });
  router.beforeEach((to, from, next) => {
    pageTitle.replace(to.meta.title);
    next();
  });
  return router;
}

// Пока не используем общий роутер на все настройки приходится добавлять прямые ссылки
export function getPages(asRoute = true) {
  return routes.map(({ name, path }) => ({
    name,
    to: asRoute ? { name } : `/settings${path}`
  }));
}
