<template>
  <base-dropdown size="big">
    <template #default="{ toggle }">
      <base-button :class="$style.button" @click="toggle">
        <div :class="$style.icon">
          <base-icon type="options-main" />
        </div>
      </base-button>
    </template>
    <template #content>
      <div :class="$style.drop">
        <template v-if="!isNextDrop">
          <base-tabs :pages="orgPages" :is-orgs="true" :is-dropdown="true" @open="handleToggle" />
          <base-delimiter top="0" bottom="10" />
          <base-tabs :pages="commonPages" :is-common="true" :is-dropdown="true" />
        </template>
        <div v-else>
          <dropdown-title :title="title" :icon="iconTitle" @back="back" />
          <base-tabs
            :pages="childrenRoutes"
            :is-common="true"
            :is-orgs="true"
            :is-dropdown="true"
            :show-title="false"
          />
        </div>
      </div>
    </template>
  </base-dropdown>
</template>
<script>
import BaseDropdown from '@/components/ui/base-dropdown/base-dropdown.vue';
import BaseTabs from './base-tabs.vue';
import BaseDelimiter from '@/components/delimiter/delimiter';
import BaseIcon from '@/components/icon.vue';
import DropdownTitle from './dropdown-title.vue';
import BaseButton from '@/components/button/button';
import routesInfo from './constants';
import { getPages as getOrganizationPages } from '../organization-settings-router';
import { getPages as getCommonPages } from '../settings-router';

export default {
  name: 'SettingsDropdown',
  components: { BaseDropdown, BaseTabs, BaseDelimiter, BaseIcon, DropdownTitle, BaseButton },
  data() {
    return {
      isNextDrop: false,
      childrenRoutes: [],
      rootRoute: {}
    };
  },
  computed: {
    orgPages() {
      console.log(getOrganizationPages());
      return getOrganizationPages();
    },
    commonPages() {
      return getCommonPages();
    },
    title() {
      return routesInfo[this.rootRoute.name].routeTitle;
    },
    iconTitle() {
      return routesInfo[this.rootRoute.name].icon;
    }
  },
  methods: {
    handleToggle(name) {
      this.rootRoute = this.orgPages.find((parent) => parent.name === name);
      this.childrenRoutes = this.rootRoute.children;
      this.isNextDrop = true;
    },
    back() {
      this.rootRoute = {};
      this.isNextDrop = false;
      this.childrenRoutes = [];
    }
  }
};
</script>

<style module>
.trigger {
  color: white;
  padding-left: 20px;
  cursor: pointer;
}
.drop {
  width: 741px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 16px 32px 32px 32px;
}
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 52px;
  background-color: transparent;
  border: none;
  &:hover {
    background-color: transparent;
  }
  &:focus-visible {
    outline: none;
  }
}
.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  &:hover {
    background-color: #202020;
  }
}
</style>

<i18n lang="json">{}</i18n>
