import { DateInterval } from './date-interval';

/**
 * Проверяет, является ли событие событием на весь день.
 *
 * @param busySlot - Объект, представляющий занятость.
 * @param busySlot.start - Дата и время начала события.
 * @param busySlot.end - Дата и время окончания события.
 * @returns Возвращает true, если событие занимает весь день, иначе false.
 */
export const isAllDayEvent = (busySlot: DateInterval): boolean => {
  const startTime = busySlot.start;
  const endTime = busySlot.end;

  const isSameDay = startTime.getDate() === endTime.getDate();
  const isStartOfDay = startTime.getHours() === 0 && startTime.getMinutes() === 0;
  const isEndOfDay = endTime.getHours() === 23 && endTime.getMinutes() === 59;

  return isSameDay && isStartOfDay && isEndOfDay;
};

/**
 * Проверяет, является ли событие событием на 2 дня.
 */
export const isTwoDaysEvent = (busySlot: DateInterval): boolean => {
  return (
    busySlot.start.getDate() < busySlot.end.getDate() ||
    busySlot.start.getMonth() !== busySlot.end.getMonth()
  );
};

/**
 * Начинается ли событие до выбранного дня интервала в виджете
 * @param event Интервал события со стороннего календаря (ответ freebusy)
 * @param day Даты начала и конца выбранного дня в виджете
 */
export const isEventStartsBeforeDay = (event: DateInterval, day: DateInterval): boolean =>
  event.start < day.start;

/**
 * Заканчивается ли событие после выбранного дня интервала в виджете
 * @param event Интервал события со стороннего календаря (ответ freebusy)
 * @param day Даты начала и конца выбранного дня в виджете
 */
export const isEventEndsAfterDay = (event: DateInterval, day: DateInterval): boolean =>
  event.end > day.end;

/**
 * Занимает ли событие весь день выбранного интервала
 * @param event Интервал события со стороннего календаря (ответ freebusy)
 * @param day Даты начала и конца выбранного дня в виджете
 */
export const isEventTakesAllDay = (event: DateInterval, day: DateInterval): boolean =>
  isEventStartsBeforeDay(event, day) && isEventEndsAfterDay(event, day);
