import { computed } from 'vue';
import { getDisplayEvents } from './get-display-events';
import { DateInterval } from '../date-interval';

interface BaseAttenders {
  email: string;
}

interface EventProps<A extends BaseAttenders = BaseAttenders> {
  start: Date;
  attenders: A[];
  busySlots: Record<string, DateInterval[]>;
}

export const useBusyEvents = (props: EventProps) => {
  const busyEvents = computed(() => getDisplayEvents(props));

  const busyEventsWithinDay = computed(() => {
    return busyEvents.value.filter((el) => !el.allDay);
  });

  const busyEventsAllDay = computed(() => {
    return busyEvents.value.filter((el) => el.allDay);
  });

  return {
    busyEventsWithinDay,
    busyEventsAllDay
  };
};
