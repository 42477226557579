import { ROW_HEIGHT, TIMECELL_WIDTH } from '../../config';
import { getDayInterval } from '../../model';
import { isAllDayEvent, isTwoDaysEvent, isEventTakesAllDay } from '../event';
import { i18nLocal } from '../i18n-local';
import { calculateEventHeight, calculateEventTop } from './event-positioning';
import { DateInterval } from '../date-interval';

interface BaseAttenders {
  email: string;
}

interface EventProps<A extends BaseAttenders = BaseAttenders> {
  start: Date;
  attenders: A[];
  busySlots: Record<string, DateInterval[]>;
}

const formatTime = (date: Date, selectedStartDate: Date, busySlot: DateInterval): string => {
  const h = date.getHours();
  const m = date.getMinutes();
  const dateDay = date.getDate();

  if (isTwoDaysEvent(busySlot)) {
    if (dateDay === selectedStartDate.getDate()) {
      return `${h <= 9 ? '0' + h : h}:${m <= 9 ? '0' + m : m}`;
    }
    if (dateDay > selectedStartDate.getDate()) {
      return `0:00`;
    }
    if (dateDay < selectedStartDate.getDate()) {
      return `0:00`;
    }
  }
  return `${h <= 9 ? '0' + h : h}:${m <= 9 ? '0' + m : m}`;
};

const createAllDayEvent = (left: string, width: string) => ({
  allDay: true,
  interval: i18nLocal('freebusy.all_day.event'),
  style: { left, width }
});

/**
 * Создает ячейку события, которое не занимает весь текущий день.
 * Может быть не только в рамках текущего дня, но и начинаться до/заканчиваться после него
 */
const createWithinDayEvent = (
  busySlot: DateInterval,
  props: EventProps,
  left: string,
  width: string
) => {
  const startInterval = formatTime(busySlot.start, props.start, busySlot);
  const endInterval = formatTime(busySlot.end, props.start, busySlot);
  const day = getDayInterval(props.start);
  const top = `${calculateEventTop(busySlot, ROW_HEIGHT, props.start)}px`;
  const height = `${calculateEventHeight(busySlot, ROW_HEIGHT, day)}px`;

  return {
    allDay: false,
    interval: `${startInterval} — ${endInterval}`,
    style: { left, top, width, height }
  };
};

const createEvent = (
  busySlot: DateInterval,
  props: EventProps,
  day: { start: Date; end: Date },
  left: string,
  width: string
) => {
  if (isAllDayEvent(busySlot) || isEventTakesAllDay(busySlot, day)) {
    return createAllDayEvent(left, width);
  }

  return createWithinDayEvent(busySlot, props, left, width);
};

const calculateStyle = (index: number, attendersLength: number) => {
  const left = `calc((100% - ${TIMECELL_WIDTH}px) * ${index} / ${attendersLength})`;
  const width = `calc((100% - ${TIMECELL_WIDTH}px) / ${attendersLength} - 10px)`;
  return { left, width };
};

export const getDisplayEvents = (props: EventProps) => {
  const selectedDay = getDayInterval(props.start);

  return props.attenders.flatMap(({ email }, index) => {
    const { left, width } = calculateStyle(index, props.attenders.length);
    return props.busySlots[email].map((busySlot) =>
      createEvent(busySlot, props, selectedDay, left, width)
    );
  });
};
